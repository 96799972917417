import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import moment from 'moment'
import {
  Avatar,
  Backdrop,
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  MenuItem,
  Tooltip,
  Switch
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { lojaAction, cidadeAction, estadoAction, usuarioAction, produtoAction, totvsAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, cnpjMask, stringUtils, phoneMask } from '../../utils'
import { Page, MapContainer, ImageUploader } from 'components'
import SaveIcon from '@material-ui/icons/Save';
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import { uploadImage } from '_services'
import { errorsUploadImage } from 'utils'
import { useConfirm } from 'material-ui-confirm'
import getInitials from 'utils/getInitials'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tituloDelivery: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'column',
    flex: 1,
    marginTop: 10
  }
})

const LojaEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, loja, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idUsuario, setIdUsuario] = useState(0);
  const [textUsuario, setTextUsuario] = useState('');
  const [, forceUpdate] = React.useState(0);

  const usuarios = useSelector((state) => state.usuario.usuario)
  const estados = useSelector((state) => state.estado.estado)

  const confirm = useConfirm()
  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)

  const resolvePromise = true;

  const { cidade } = useSelector((state) => state.cidade)
  const [ready, setReady] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { totalRegistros } = props.produto

  const idLoja = params.id;

  useEffect(() => {
    dispatch(estadoAction.getEstadoSelect())

    dispatch(lojaAction.clearUsuariosLojas())

    if (params.id) {
      dispatch(lojaAction.getLojaById(params.id))
    } else {
      dispatch(lojaAction.clear())
      dispatch(lojaAction.clearUsuariosLojas())
    }

    dispatch(usuarioAction.getUsuarioSelect())
    if (props.loja.idEstado != undefined && props.loja.idEstado !== 0 && props.loja.idEstado !== '') {
      dispatch(cidadeAction.getCidadeByIdEstado(props.loja.idEstado))
    }

    const interval = setInterval(() => {
      setReady(true)
    }, 1500);
    return () => clearInterval(interval);

  }, []);

  const firePromise = () => {
    toast.promise(
      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolvePromise ? resolve(null) : reject(null);
          resolvePromise = !resolvePromise;
        }, 3000);
      }),
      {
        pending: 'Atualizando cardápio',
      }
    );
  };

  useEffect(() => {

    let filterModel = {
      IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
    };

    dispatch(produtoAction.getProduto(filterModel, true));

  }, []);

  useEffect(() => {

    let filterModel = {
      IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
    };

    dispatch(produtoAction.getProduto(filterModel, true));

  }, [totalRegistros]);

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...loja,
        caminhoImagem: null
      }
      dispatch(
        lojaAction.editLojaInfo(loja.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(lojaAction.onChangeProps('caminhoImagem', event2))
      setImagem(null)
    })
  }

  const onChangeImage = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...loja,
          caminhoImagem: response.toString()
        }

        if (loja.id !== 0) {
          dispatch(
            lojaAction.editLojaInfo(loja.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(lojaAction.onChangeProps('caminhoImagem', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {
    setImagem(loja.caminhoImagem)
  }, [loja.caminhoImagem, open]);

  useEffect(() => {
    // forceUpdate(n => !n);
  }, [open, imagem]);

  useEffect(() => {
    if (props.loja.idEstado != undefined && props.loja.idEstado !== 0 && props.loja.idEstado !== '') {
      dispatch(cidadeAction.getCidadeByIdEstado(props.loja.idEstado))
    }
  }, [props.loja.idEstado])

  useEffect(() => {

  }, [ready, props.loja.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(lojaAction.onChangeProps(prop, event))
  }

  const handleSelectChangeState = prop => event => {
    setIdUsuario(event.target.value);
    setTextUsuario(event.nativeEvent.target.innerText);
  };

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      idTotvs: props.loja.idTotvs,
      idYungas: props.loja.idYungas,
      nome: props.loja.nome,
      razaoSocial: props.loja.razaoSocial,
      codigoEstabelecimento: props.loja.codigoEstabelecimento,
      regimeTributario: props.loja.regimeTributario,
      cnpj: props.loja.cnpj
        .split('.')
        .join('')
        .replace(/[-/.]/g, ''),
      ie: props.loja.ie,
      idCidade: props.loja.idCidade,
      idEstado: props.loja.idEstado,
      tipoIntegracao: props.loja.tipoIntegracao,
      qrCode: props.loja.qrCode,
      latitude: props.loja.latitude,
      longitude: props.loja.longitude,
      email: props.loja.email,
      senhaEmail: props.loja.senhaEmail,
      telefone: (props.loja.telefone ? props.loja.telefone.replace(/\D/g, '') : null),
      usuariosLojas: props.loja.usuariosLojas,
      caminhoImagem: props.loja.caminhoImagem,
      toGo: props.loja.toGo,
      delivery: props.loja.delivery
    }

    if (params.id) {
      dispatch(lojaAction.editLojaInfo(params.id, payload, true))
    } else {
      dispatch(lojaAction.createLoja(payload))
    }
  };

  const handleSubmitAtualizarCardapio = () => {
    if (idLoja) {
      //firePromise();
      dispatch(totvsAction.updateProdutosByIdLoja(idLoja))
    }
  };

  const handleSubmitLojaEmail = (event) => {

    let payload = {
      id: params.id,
      email: props.loja.email,
      senhaEmail: props.loja.senhaEmail
    }

    if (params.id) {
      dispatch(lojaAction.editLojaEmailInfo(params.id, payload, true))
    }
  };

  const handleAddOnList = e => {

    if (props.loja.usuariosLojas.filter(ts => ts.idUsuario == parseInt(idUsuario)).length > 0) {
      toast.error('Erro ao adicionar! Este usuário já existe!')
    }
    else {

      if (idUsuario != '' && idUsuario != '0') {
        props.loja.usuariosLojas.push({ 'idUsuario': idUsuario, 'idLoja': props.loja.id, 'usuario': { 'nome': textUsuario } });
        forceUpdate(n => !n);
        setIdUsuario(0);

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos um usuário!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {

    props.loja.usuariosLojas = props.loja.usuariosLojas.filter(ts => ts.idUsuario != parseInt(id));
    forceUpdate(n => !n);
  };

  function excludeFilter(obj) {
    if (props.loja.usuariosLojas && props.loja.usuariosLojas.length > 0) {
      if (props.loja.usuariosLojas.filter(fl => fl.idUsuario == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Loja'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Loja'}</b>
      </Typography>
    )
  }

  function EditEmail() {
    return (
      <Typography variant="h4">
        <b>{'Editar E-mail'}</b>
      </Typography>
    )
  }

  function EditCardapio() {
    return (
      <Typography variant="h4">
        <b>{'Atualizar Cardápio'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  function SegHeaderEmail() {
    if (params.id) {
      return <EditEmail />
    } else {
      return ''
    }
  }

  function SegHeaderAtualizarCardapio() {
    if (params.id) {
      return <EditCardapio />
    } else {
      return ''
    }
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Lojas - Editar Loja'
          : 'Gerência de Lojas - Nova Loja'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={images}
              onChangeImage={onChangeImage}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={500000}
              resolutionWidth={1100}
              resolutionHeight={1100}
              resolutionType={"less"}
              removeImage={removeImage}
              props={loja}
              imagem={imagem}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />
          </Grid>
        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formLoja"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Lojas"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={5} md={5} xl={5} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        onInput={stringUtils.toInputUppercase}
                        margin="normal"
                        onChange={handleChange('nome')}
                        value={props.loja.nome}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={5} md={5} xl={5} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="razaoSocial"
                        label="Razão Social"
                        margin="normal"
                        onChange={handleChange('razaoSocial')}
                        value={props.loja.razaoSocial}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cnpj"
                        label="CNPJ *"
                        margin="normal"
                        onChange={handleChange('cnpj')}
                        value={cnpjMask(props.loja.cnpj)}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        className={classes.textField}
                        label="Regime Tributário *"
                        labelId="regimeTributario"
                        value={props.loja.regimeTributario}
                        onChange={handleChange('regimeTributario')}
                        name="regimeTributario"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          name: "regimeTributario",
                          id: "regimeTributario"
                        }}
                        input={<Input id="regimeTributario" />}
                        margin="normal"
                        variant="outlined"
                        id="regimeTributario"
                      >
                        <MenuItem disabled value={''}>Regime Tributário</MenuItem>
                        <MenuItem value={'MEI'}>MEI</MenuItem>
                        <MenuItem value={'SN'}>Simples Nacional</MenuItem>
                        <MenuItem value={'LP'}>Lucro Presumido</MenuItem>
                        <MenuItem value={'LR'}>Lucro Real</MenuItem>
                        <MenuItem value={'I'}>Isento</MenuItem>
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="ie"
                        label="Inscrição estadual"
                        margin="normal"
                        onChange={handleChange('ie')}
                        value={props.loja.ie || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="telefone"
                        label="Telefone *"
                        margin="normal"
                        onChange={handleChange('telefone')}
                        value={phoneMask(props.loja.telefone)}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                      />
                    </Grid>
                    {(props.loja.id === 0 ?
                      <>
                        <Grid item md={12} xs={12} style={{ marginTop: '4px', marginBottom: '3px' }}>
                          <Divider />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="E-mail"
                            value={props.loja.email || ''}
                            className={classes.textField}
                            onChange={handleChange('email')}
                            id="email"
                            name="email"
                            autoComplete="email"
                            validators={['isEmail']}
                            errorMessages={[
                              'E-mail inválido'
                            ]}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={props.loja.senhaEmail || ''}
                            onChange={handleChange('senhaEmail')}
                            name="senhaEmail"
                            label="Senha do E-mail"
                            type={showPassword ? 'text' : 'password'}
                            id="senhaEmail"
                            autoComplete="current-senha"
                          />
                        </Grid>
                        <Grid item md={12} xs={12} style={{ marginTop: '3px', marginBottom: '4px' }}>
                          <Divider />
                        </Grid>
                      </>
                      : null
                    )}
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idEstado"
                        label="Estado *"
                        value={props.loja.idEstado}
                        onChange={handleChange('idEstado')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idEstado"
                        variant="outlined"
                      >
                        <MenuItem disabled value="">
                          <em>Estado *</em>
                        </MenuItem>
                        {!isEmpty(estados) &&
                          undefined !== estados &&
                          estados.length &&
                          estados.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        disabled={
                          props.loja.idEstado === ''
                            ? true
                            : false
                        }
                        variant="outlined"
                        id="idCidade"
                        label="Cidade *"
                        value={props.loja.idCidade}
                        onChange={handleChange('idCidade')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idCidade"
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem disabled value="">
                          <em>Cidade *</em>
                        </MenuItem>
                        {!isEmpty(cidade) &&
                          cidade.length &&
                          cidade.map((row, index) => (
                            <MenuItem key={'c' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="qr_code"
                        label="QR Code *"
                        margin="normal"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        onChange={handleChange('qrCode')}
                        value={props.loja.qrCode}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        className={classes.textField}
                        fullWidth
                        id="latitude"
                        label="Latitude *"
                        margin="normal"
                        onChange={handleChange('latitude')}
                        value={props.loja.latitude}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        className={classes.textField}
                        fullWidth
                        id="longitude"
                        label="Longitude *"
                        margin="normal"
                        onChange={handleChange('longitude')}
                        value={props.loja.longitude}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography variant="h4" align="left">
                        <b>Dados de Integração</b>
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        className={classes.textField}
                        label="Tipo de Integração"
                        labelId="tipoIntegracao"
                        value={props.loja.tipoIntegracao}
                        onChange={handleChange('tipoIntegracao')}
                        name="tipoIntegracao"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          name: "TipoIntegracao",
                          id: "tipoIntegracao"
                        }}
                        input={<Input id="tipoIntegracao" />}
                        margin="normal"
                        variant="outlined"
                        id="tipoIntegracao"
                      >
                        <MenuItem disabled value={''}>Tipo Integração</MenuItem>
                        <MenuItem value={'BITBAR'}>BITBAR</MenuItem>
                        <MenuItem value={'LINX'}>LINX</MenuItem>
                        <MenuItem value={'TOTVS'}>TOTVS</MenuItem>
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="idTotvs"
                        label="ID (TOTVS\LINX)"
                        margin="normal"
                        onChange={handleChange('idTotvs')}
                        value={props.loja.idTotvs || ''}
                        variant="outlined"
                        disabled={props.loja.tipoIntegracao != 'TOTVS' && props.loja.tipoIntegracao != 'LINX'}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        onInput={stringUtils.toInputUppercase}
                        id="codigoEstabelecimento"
                        label="Código do Estabelecimento (TOTVS)"
                        margin="normal"
                        onChange={handleChange('codigoEstabelecimento')}
                        value={props.loja.codigoEstabelecimento}
                        variant="outlined"
                        disabled={props.loja.tipoIntegracao != 'TOTVS'}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="idYungas"
                        label="ID (YUNGAS)"
                        margin="normal"
                        onChange={handleChange('idYungas')}
                        value={props.loja.idYungas || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        readOnly
                        fullWidth
                        type="datetime"
                        value={props.loja.dataUltimaVendaImportada ? moment(props.loja.dataUltimaVendaImportada).format("DD/MM/yyyy HH:mm") : ''}
                        name="dataUltimaVendaImportada"
                        label="Data da Última Venda Importada"
                        id="dataUltimaVendaImportada"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <CardContent>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.loja.toGo}
                              onChange={handleChange('toGo')}
                              color="primary"
                            />
                          }
                          label="To go"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.loja.delivery}
                              onChange={handleChange('delivery')}
                              color="primary"
                            />
                          }
                          label="Delivery"
                        />
                      </CardContent>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} >
                      <MenuItem>
                        Coordenadas
                      </MenuItem>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} style={{ height: '300px', width: '100%', position: 'relative' }}>
                      {(ready ?
                        <MapContainer latitude={props.loja.latitude} longitude={props.loja.longitude} nome={props.loja.nome} />
                        :
                        null
                      )}
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                      <Divider />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idUsuario"
                        label="Usuários vinculados *"
                        value={idUsuario}
                        onChange={handleSelectChangeState('idUsuario')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idUsuario"
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Usuário *</em>
                        </MenuItem>
                        {!isEmpty(usuarios) &&
                          undefined !== usuarios &&
                          usuarios.length &&
                          usuarios.filter(excludeFilter).map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                      <Button style={{ width: '200px', marginLeft: '15px' }} name="btnUsuarios" id="btnUsuarios" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          {
                            (props.loja.usuariosLojas != null && props.loja.usuariosLojas.length > 0 ?
                              props.loja.usuariosLojas.map(n => {
                                return (
                                  (n.id !== '' ?
                                    <Button color="default" onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                      <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                        {(n.usuario ? n.usuario.nome + (n.usuario.sobrenome != '' ? ' ' + n.usuario.sobrenome : '') : n.idUsuario)}
                                      </Badge>
                                      &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                    : null)
                                );
                              })
                              : null)
                          }
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>

                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/Lojas')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.loja.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.loja.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.loja.isLoading ? 'Atualizando loja...' : 'Atualizar') : (props.loja.isLoading ? 'Salvando loja...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
          {(props.loja.id !== 0 ?
            <Grid item lg={12} md={12} xl={12} xs={12} style={{ marginTop: '25px' }}>
              <Grid item md={12} xs={12}>
                <Card>
                  <ValidatorForm
                    className={classes.form}
                    ref={formRef}
                    id="formLojaEmail"
                    onSubmit={(event) => handleSubmitLojaEmail(event)}
                  >
                    <CardHeader
                      subheader="Cadastro de Lojas"
                      title={<SegHeaderEmail />}
                    />
                    <Divider />
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="E-mail"
                            value={props.loja.email || ''}
                            className={classes.textField}
                            onChange={handleChange('email')}
                            id="email"
                            name="email"
                            autoComplete="email"
                            validators={['isEmail']}
                            errorMessages={[
                              'E-mail inválido'
                            ]}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={props.loja.senhaEmail || ''}
                            onChange={handleChange('senhaEmail')}
                            name="senhaEmail"
                            label="Senha do E-mail"
                            type={showPassword ? 'text' : 'password'}
                            id="senhaEmail"
                            autoComplete="current-senha"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                          * Campos obrigatórios
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlgin: 'right',
                        justifyContent: 'right'
                      }}>
                        <Button
                          color="default"
                          onClick={() => history.push('/Lojas')}
                          variant="contained"
                        >
                          Cancelar
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={props.loja.isLoading}
                          startIcon={<SaveIcon />}
                          endIcon={props.loja.isLoading && <CircularProgress size={24} />}
                        >
                          {params.id ? (props.loja.isLoading ? 'Atualizando email...' : 'Atualizar e-mail') : (props.loja.isLoading ? 'Salvando email...' : 'Salvar e-mail')}
                        </Button>
                      </Grid>
                    </CardActions>
                  </ValidatorForm>
                </Card>
              </Grid>
            </Grid>
            :
            null
          )}

          {(props.loja.id !== 0 ?
            <Grid item lg={12} md={12} xl={12} xs={12} style={{ marginTop: '25px' }}>
              <Grid item md={12} xs={12}>
                <Card>
                  <ValidatorForm
                    className={classes.form}
                    ref={formRef}
                    id="formLojaCardapio"
                    onSubmit={(event) => handleSubmitAtualizarCardapio(event)}
                  >
                    <CardHeader
                      subheader="Cadastro de Lojas"
                      title={<SegHeaderAtualizarCardapio />}
                    />
                    <Divider />
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            readOnly
                            fullWidth
                            value={totalRegistros}
                            name="qtdProdutos"
                            label="Quantidade de produtos"
                            id="qtdProdutos"
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            readOnly
                            fullWidth
                            type="date"
                            value={props.loja.dataUltimaAtualizacaoCardapio != null ? moment(props.loja.dataUltimaAtualizacaoCardapio).format("yyyy-MM-DD") : "yyyy-MM-DD"}
                            name="dataUltimaAtualizacaoCardapio"
                            label="Data da última atualização"
                            id="dataUltimaAtualizacaoCardapio"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlgin: 'right',
                        justifyContent: 'right'
                      }}>
                        <Button
                          color="default"
                          onClick={() => history.push('/Lojas')}
                          variant="contained"
                        >
                          Cancelar
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={props.produto.isLoading}
                          startIcon={<SaveIcon />}
                          endIcon={props.produto.isLoading && <CircularProgress size={24} />}
                        >
                          {params.id ? (props.produto.isLoading ? 'Atualizando cardápio...' : 'Atualizar cardápio') : (props.produto.isLoading ? 'Salvando cardápio...' : 'Salvar cardápio')}
                        </Button>
                      </Grid>
                    </CardActions>
                  </ValidatorForm>
                </Card>
              </Grid>
            </Grid>
            :
            null
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

LojaEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return state
}

const connectedLojaEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(LojaEdit))
)
export { connectedLojaEditPage as LojaEdit };
